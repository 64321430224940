import { gql } from '@faststore/graphql-utils'
import type { CartItem as SDKCartItem, Cart as SDKCart } from '@faststore/sdk'
import type {
  ValidateCartMutationMutation,
  ValidateCartMutationMutationVariables,
  CartItemFragment,
  CartMessageFragment,
  IStoreOffer,
} from '@generated/graphql'
import axios from 'axios'

import { request } from '../graphql/request'

// export const LAST_CART_VALIDATED_STORAGE_KEY = 'faststore:cart:lastValidated'
export const LAST_CONTEXT_VALIDATED_STORAGE_KEY =
  'faststore:context:lastValidated'
// export const MIN_CART_WAITING_TIME_FOR_REVALIDATION = 15000
// export const MAX_REFRESH_CART_WAITING_TIME_FOR_REVALIDATION = 5
export const MIN_CONTEXT_WAITING_TIME_FOR_REVALIDATION = 30000

export interface CartItem extends SDKCartItem, CartItemFragment {
  index?: number
}

export interface Cart extends SDKCart<CartItem> {
  messages?: CartMessageFragment[]
  shouldSplitItem?: boolean
}

export const ValidateCartMutation = gql`
  mutation ValidateCartMutation($cart: IStoreCart!) {
    validateCart(cart: $cart) {
      order {
        orderNumber
        acceptedOffer {
          ...CartItem
        }
      }
      messages {
        ...CartMessage
      }
    }
  }
  fragment CartMessage on StoreCartMessage {
    text
    status
  }
  fragment CartItem on StoreOffer {
    seller {
      identifier
    }
    quantity
    price
    listPrice
    itemOffered {
      sku
      name
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      image {
        url
        alternateName
      }
      brand {
        name
      }
      isVariantOf {
        name
        productGroupID
        additionalProperty {
          propertyID
          name
          value
          valueReference
        }
        hasVariant {
          ...skuFragment
        }
      }
      gtin
      additionalProperty {
        propertyID
        name
        value
        valueReference
      }
      productSpecifications {
        unitMultiplier
      }
    }
  }
`

export const isGift = (item: CartItem) => item.price === 0

export const getItemId = (
  item: Pick<CartItem, 'itemOffered' | 'seller' | 'price'>
) =>
  [
    item.itemOffered.sku,
    item.seller.identifier,
    item.price,
    item.itemOffered.additionalProperty
      ?.map(({ propertyID }) => propertyID)
      .join('-'),
  ]
    .filter(Boolean)
    .join('::')

export const getCartFromOrderForm = (orderForm: {
  id?: string // Field containing the orderFormId in the replaceCart response
  orderFormId?: string // Field containing the orderFormId in the getCartInformation response
  items: any
}) => ({
  id: orderForm?.id ?? orderForm?.orderFormId ?? '',
  items: orderForm.items.map(
    (item: {
      sellingPrice: number
      price: number
      listPrice: number
      seller: string
      quantity: number
      id: string
      imageUrls?: { [s: string]: unknown } | ArrayLike<unknown> // Field containing the imageUrl in the replaceCart response
      imageUrl?: string // Field containing the imageUrl in the getCartInformation response
      skuName: string
      attachments: [{ name: string; content: any }]
    }) => ({
      price:
        typeof item.sellingPrice !== 'undefined'
          ? item.sellingPrice
          : item.price,
      listPrice: item.listPrice,
      seller: { identifier: item.seller },
      quantity: item.quantity,
      itemOffered: {
        sku: item.id,
        image: item.imageUrls
          ? Object.entries(item.imageUrls).map(([_, val]) => ({
              url: `https:${val}`,
              alternateName: '',
            }))
          : item.imageUrl
          ? [{ url: item.imageUrl, alternateName: '' }]
          : [{ url: '', alternateName: '' }],
        name: item.skuName,
        additionalProperty: item.attachments.map(
          (attachment: { name: string; content: any }) => ({
            name: attachment.name,
            value: attachment.content,
            valueReference: 'ATTACHMENT',
          })
        ),
      },
    })
  ),
})

const reduceCartItems = (cart: Cart) => {
  return cart.items.map(
    ({
      id,
      index,
      itemOffered: { sku },
      quantity,
      price,
      listPrice,
      seller: { identifier },
    }) => ({
      id,
      index,
      sku,
      quantity,
      price,
      listPrice,
      seller: identifier,
    })
  )
}

const areCartsEqual = (currentCart: Cart, savedCart: Cart) => {
  return (
    JSON.stringify({
      id: currentCart.id,
      items: reduceCartItems(currentCart),
    }) ===
    JSON.stringify({ id: savedCart.id, items: reduceCartItems(savedCart) })
  )
}

// const shouldRevalidateCart = (lastValidated: string | null) => {
//   let currentTime = Date.now()
//   let numLastValidated = lastValidated ? Number(lastValidated) : 0
//   return (
//     currentTime - numLastValidated <
//       MAX_REFRESH_CART_WAITING_TIME_FOR_REVALIDATION || // check mainly added to force cart revalidation after order placed
//     currentTime - numLastValidated > MIN_CART_WAITING_TIME_FOR_REVALIDATION
//   )
// }

export const validateCart = async (
  cart: Cart,
  retry: number = 0
): Promise<Cart | null> => {
  try {
    const { validateCart: validated = null } = await request<
      ValidateCartMutationMutation,
      ValidateCartMutationMutationVariables
    >(ValidateCartMutation, {
      cart: {
        order: {
          orderNumber: cart.id,
          shouldSplitItem: cart.shouldSplitItem ?? false,
          acceptedOffer: cart.items.map(
            ({
              price,
              listPrice,
              seller,
              quantity,
              itemOffered,
            }): IStoreOffer => ({
              price,
              listPrice,
              seller,
              quantity,
              itemOffered: {
                sku: itemOffered.sku,
                image: itemOffered.image,
                name: itemOffered.name,
                additionalProperty: itemOffered.additionalProperty,
              },
            })
          ),
        },
      },
    })

    // sessionStorage.setItem(LAST_CART_VALIDATED_STORAGE_KEY, String(Date.now()))

    return (
      validated && {
        id: validated.order.orderNumber,
        items: validated.order.acceptedOffer.map((item, index) => ({
          ...item,
          index,
          id: getItemId(item),
        })),
        messages: validated.messages,
      }
    )
  } catch (err) {
    if (retry < 3) {
      return validateCart(cart, retry + 1)
    } else {
      return null
    }
  }
}

export const validateSharedCart = async (cart: Cart): Promise<Cart | null> => {
  if (typeof cart !== 'undefined') {
    // const lastValidated = sessionStorage.getItem(
    //   LAST_CART_VALIDATED_STORAGE_KEY
    // )
    // if (shouldRevalidateCart(lastValidated)) {
    const { data: savedOrderForm } = await axios.get(
      `/api/checkout/getCartInformation?orderFormId=${cart.id}`
    )

    const savedCart = getCartFromOrderForm(savedOrderForm)
    const validatedCart = await validateCart(savedCart)

    if (validatedCart !== null && !areCartsEqual(cart, validatedCart)) {
      return validatedCart
    }

    if (
      validatedCart === null &&
      cart.items.length > 0 &&
      savedCart.items.length === 0 // To be tested: cart.items.length !== savedCart.items.length
    ) {
      return savedCart
    }
    // }
  }

  return null
}