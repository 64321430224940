import { AmericanExpressIcon } from 'src/components/icons/AmericanExpressIcon'
import { FacebookIcon } from 'src/components/icons/FacebookIcon'
import { InstagramIcon } from 'src/components/icons/InstagramIcon'
import { LinkedinIcon } from 'src/components/icons/LinkedinIcon'
import { MastercardIcon } from 'src/components/icons/MastercardIcon'
import { ParkingIcon } from 'src/components/icons/ParkingIcon'
import { PaypalIcon } from 'src/components/icons/PaypalIcon'
import { TiktokIcon } from 'src/components/icons/TiktokIcon'
import { TwitterIcon } from 'src/components/icons/TwitterIcon'
import { VirtualIcon } from 'src/components/icons/VirtualIcon'
import { WashIcon } from 'src/components/icons/WashIcon'
import { VisaIcon } from 'src/components/icons/VisaIcon'
import { YoutubeIcon } from 'src/components/icons/YoutubeIcon'
import { AgilityIcon } from 'src/components/icons/AgilityIcon'
import { ArcalabIcon } from 'src/components/icons/ArcalabIcon'
import { ArcaCafeIcon } from 'src/components/icons/ArcaCafeIcon'
import { BrandIcon } from 'src/components/icons/BrandIcon'

const services = [
  {
    name: <ParkingIcon />,
    text: 'Parking',
  },
  {
    name: <WashIcon />,
    text: 'Pet wash e lavanderia',
    href: '/arcaplanet-pet-wash-scopri-il-servizio-di-lavaggio-esclusivo-nei-pet-store',
  },
  {
    name: <AgilityIcon />,
    text: 'Agility',
  },
  {
    name: <ArcalabIcon />,
    text: 'Arcalab',
  },
  {
    name: <ArcaCafeIcon />,
    text: 'Arcacafè',
  },
  {
    name: <VirtualIcon />,
    text: 'Virtual tour',
  },
  {
    name: <BrandIcon />,
    text: 'Brand esclusivi',
  },
]

const promotions = [
  {
    text: 'Online',
    href: '/promozioni-online',
  },
  {
    text: 'In negozio',
    href: '/promozioni-negozio',
  },
  {
    text: 'Volantino del mese',
    href: '/volantino-mese',
  },
  {
    text: 'Programma fedeltà',
    href: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore',
  },
]

const paymentIcons = [
  {
    name: <MastercardIcon />,
    text: 'Mastercard',
  },
  {
    name: <VisaIcon />,
    text: 'Visa',
  },
  {
    name: <PaypalIcon />,
    text: 'Paypal',
  },
  {
    name: <AmericanExpressIcon />,
    text: 'AmericanExpress',
  },
]

const socialIcons = [
  {
    href: 'https://www.facebook.com/Arcaplanet/',
    title: 'Facebook',
    iconName: <FacebookIcon />,
  },
  {
    href: 'https://www.instagram.com/arcaplanet/',
    title: 'Instagram',
    iconName: <InstagramIcon />,
  },
  {
    href: 'https://www.youtube.com/c/arcaplanet',
    title: 'Youtube',
    iconName: <YoutubeIcon />,
  },
  {
    href: 'https://www.tiktok.com/@arcaplanet',
    title: 'Tiktok',
    iconName: <TiktokIcon />,
  },
  {
    href: 'https://twitter.com/Arcaplanet',
    title: 'Twitter',
    iconName: <TwitterIcon />,
  },
  {
    href: 'https://www.linkedin.com/company/agrifarma-s-p-a-/',
    title: 'Linkedin',
    iconName: <LinkedinIcon />,
  },
]

const legaltext = (
  <p>
    AGRIFARMA S.P.A a socio unico Via Roncaglia, 12, 20146-Milano (MI) P.IVA
    01067670990 C.F 01421010487 Capitale Sociale Euro 1.121.363,00 I.V Iscritta
    al registro imprese di MILANO MONZA BRIANZA E LODI PEC:
    info@pec.arcaplanet.net
  </p>
)

const links = [
  {
    title: 'Arcaplanet',
    items: [
      {
        href: '/identita-e-valori',
        name: 'Identitá e valori',
      },
      {
        href: '/azienda',
        name: 'Azienda',
      },
      {
        href: '/unisciti-a-noi',
        name: 'Unisciti a noi',
      },
      {
        href: '/persone',
        name: 'Persone',
      },
      {
        href: '/la-nostra-academy',
        name: 'La nostra Academy',
      },
      {
        href: '/il-percorso-di-selezione',
        name: 'Il percorso di selezione',
      },
      {
        href: 'https://magazine.arcaplanet.it/',
        name: 'Magazine',
      },
      {
        href: 'https://magazine.arcaplanet.it/news/',
        name: 'News e media',
      },
      {
        href: '/i-nostri-brand-esclusivi',
        name: 'Brand esclusivi',
      },
    ],
  },
  {
    title: 'Negozi',
    items: [
      {
        href: '/pet-store',
        name: 'Pet store Arcaplanet',
      },
      {
        href: 'https://my.matterport.com/show/?m=HUJMmgzHbYh',
        name: 'Tour virtuale',
      },
      {
        href: '/click-and-collect-ordina-online-e-ritira-gratis-in-un-negozio-arcaplanet',
        name: 'Ordina e ritira',
      },
      {
        href: '/servizi-esclusivi',
        name: 'Servizi esclusivi',
      },
      {
        href: 'https://negozi.arcaplanet.it/',
        name: 'Trova negozio',
      },
      {
        href: 'https://magazine.arcaplanet.it/eventi/',
        name: 'Eventi',
      },
    ],
  },
  {
    title: 'Iniziative e promozioni',
    items: [
      {
        href: '/iniziative',
        name: 'Iniziative',
      },
      {
        href: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore',
        name: 'Programma fedeltà',
      },
      {
        href: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore',
        name: 'Sottoscrivi Arcacard',
      },
      {
        href: 'https://storiedicuccioli.arcaplanet.it/',
        name: 'Storie di cuccioli',
      },
      {
        href: '/codici-sconto',
        name: 'Codici sconto',
      },
      {
        href: '/foodstock',
        name: 'Progetto Foodstock',
      },
      {
        href: '/asino',
        name: 'Fondazione ASINO',
      },
      {
        href: '/your-pet-stories',
        name: 'Your Pet Stories',
      },
      {
        href: '/inseparabili-storie-a-4-zampe',
        name: 'Inseparabili - Storie a 4 zampe',
      },
    ],
  },
  {
    title: 'Informazioni utili',
    items: [
      {
        href: 'https://my.arcaplanet.it/assistenza/s/?_ga=2.224566867.833902778.1664376185-39936050.1664272887',
        name: 'Assistenza clienti',
      },
      {
        href: '/privacy',
        name: 'Privacy Policy ',
      },
      {
        href: '/cookies-policy',
        name: 'Cookies Policy ',
      },
      {
        href: '/condizioni-generali-di-vendita',
        name: 'Condizioni di vendita',
      },
      {
        href: '/condizioni-di-utilizzo',
        name: 'Condizioni di utilizzo',
      },
      {
        href: '/whistleblowing',
        name: 'Whistleblowing',
      },
      {
        href: 'https://ambiente.arcaplanet.it/?_ga=2.158094579.833902778.1664376185-39936050.1664272887',
        name: 'Etichettatura ambientale',
      },
    ],
  },
]

const linksmobile = [
  {
    title: 'Arcaplanet',
    items: [
      {
        href: '/identita-e-valori',
        name: 'Identitá e valori',
      },
      {
        href: '/azienda',
        name: 'Azienda',
      },
      {
        href: '/unisciti-a-noi',
        name: 'Unisciti a noi',
      },
      {
        href: '/persone',
        name: 'Persone',
      },
      {
        href: '/la-nostra-academy',
        name: 'La nostra Academy',
      },
      {
        href: '/il-percorso-di-selezione',
        name: 'Il percorso di selezione',
      },
      {
        href: 'https://magazine.arcaplanet.it/',
        name: 'Magazine',
      },
      {
        href: 'https://magazine.arcaplanet.it/news/',
        name: 'News e media',
      },
      {
        href: '/i-nostri-brand-esclusivi',
        name: 'Brand esclusivi',
      },
    ],
  },
  {
    title: 'Negozi',
    items: [
      {
        href: '/pet-store',
        name: 'Pet store Arcaplanet',
      },
      {
        href: 'https://my.matterport.com/show/?m=HUJMmgzHbYh',
        name: 'Tour virtuale',
      },
      {
        href: '/click-and-collect-ordina-online-e-ritira-gratis-in-un-negozio-arcaplanet',
        name: 'Ordina e ritira',
      },
      {
        href: '/servizi-esclusivi',
        name: 'Servizi esclusivi',
      },
      {
        href: 'https://negozi.arcaplanet.it/',
        name: 'Trova negozio',
      },
      {
        href: 'https://magazine.arcaplanet.it/eventi/',
        name: 'Eventi',
      },
    ],
  },
  {
    title: 'Iniziative e promozioni',
    items: [
      {
        href: '/iniziative',
        name: 'Iniziative',
      },
      {
        href: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore',
        name: 'Programma fedeltà',
      },
      {
        href: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore/',
        name: 'Sottoscrivi Arcacard',
      },
      {
        href: 'https://storiedicuccioli.arcaplanet.it/',
        name: 'Storie di cuccioli',
      },
      {
        href: '/codici-sconto',
        name: 'Codici sconto',
      },
      {
        href: '/foodstock',
        name: 'Progetto Foodstock',
      },
      {
        href: '/asino',
        name: 'Fondazione ASINO',
      },
      {
        href: '/your-pet-stories',
        name: 'Your Pet Stories',
      },
      {
        href: '/inseparabili-storie-a-4-zampe',
        name: 'Inseparabili - Storie a 4 zampe',
      },
    ],
  },
  {
    title: 'Informazioni utili',
    items: [
      {
        href: 'https://my.arcaplanet.it/assistenza/s/?_ga=2.224566867.833902778.1664376185-39936050.1664272887',
        name: 'Assistenza clienti',
      },
      {
        href: '/privacy',
        name: 'Privacy Policy ',
      },
      {
        href: '/cookies-policy',
        name: 'Cookies Policy ',
      },
      {
        href: '/condizioni-generali-di-vendita',
        name: 'Condizioni di vendita',
      },
      {
        href: '/condizioni-di-utilizzo',
        name: 'Condizioni di utilizzo',
      },
      {
        href: '/whistleblowing',
        name: 'Whistleblowing',
      },
      {
        href: 'https://ambiente.arcaplanet.it/?_ga=2.158094579.833902778.1664376185-39936050.1664272887',
        name: 'Etichettatura ambientale',
      },
    ],
  },
  {
    title: 'I nostri servizi',
    items: [
      {
        href: '',
        name: 'Parking',
      },
      {
        href: '/arcaplanet-pet-wash-scopri-il-servizio-di-lavaggio-esclusivo-nei-pet-store',
        name: 'Pet wash e lavanderia',
      },
      {
        href: '',
        name: 'Agility',
      },
      {
        href: '',
        name: 'Arcalab',
      },
      {
        href: '',
        name: 'Arcacafè',
      },
      {
        href: '',
        name: 'Virtual tour',
      },
      {
        href: '',
        name: 'Brand esclusivi',
      },
    ],
  },
  {
    title: 'Promozioni',
    items: [
      {
        href: '/promozioni-online',
        name: 'Online',
      },
      {
        href: '/promozioni-negozio',
        name: 'In negozio',
      },
      {
        href: '/volantino-mese',
        name: 'Volantino del mese',
      },
      {
        href: 'https://magazine.arcaplanet.it/nuova-arcacard-ogni-acquisto-ha-piu-valore',
        name: 'Programma fedeltà',
      },
    ],
  },
]

export {
  services,
  promotions,
  paymentIcons,
  socialIcons,
  legaltext,
  links,
  linksmobile,
}
