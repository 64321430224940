import { useCallback } from 'react'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'

import { useUI } from '../ui'
import { useCart } from './useCart'

export const useCartToggleButton = () => {
  const { totalItems, items, total } = useCart()
  const {
    currency: { code },
  } = useSession()

  const { displayMinicart, openMinicart, closeMinicart } = useUI()

  const onClick = useCallback(() => {
    if (displayMinicart) {
      closeMinicart()
    } else {
      openMinicart()

      const eventItems = items.map((item) => {
        const itemCategory = item.itemOffered.breadcrumbList?.itemListElement
          .slice(0, -1)
          .map((x) => x.name)

        const itemListName = itemCategory.join(' ')

        return {
          index: 0,
          item_list_id: null,
          item_category: itemCategory,
          item_list_name: itemListName,
          item_id: item.itemOffered.isVariantOf.productGroupID,
          item_name: item.itemOffered.isVariantOf.name,
          item_brand: item.itemOffered.brand.name,
          item_variant: item.itemOffered.sku,
          quantity: item.quantity,
          price: item.price,
          discount: +(item.listPrice - item.price).toFixed(2),
          full_price: item.listPrice,
          discount_percentage: +(1 - item.price / item.listPrice).toFixed(2),
          currency: code as CurrencyCode,
          item_variant_name: item.itemOffered.name,
          product_reference_id: Number(item.itemOffered.gtin),
          coupon: null,
        }
      })

      sendAnalyticsEvent<UnknownEvent>({
        name: 'view_cart',
        params: {
          currency: code as CurrencyCode,
          value: total,
          items: eventItems,
        },
      })
    }
  }, [closeMinicart, displayMinicart, openMinicart, items, total, code])

  return {
    onClick,
    'data-testid': 'cart-toggle',
    'data-items': totalItems,
  }
}
