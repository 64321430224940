import { useSession } from '@faststore/sdk'
import type { FC } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useUserAccountAPI } from 'src/sdk/userAccount/useUserAccountAPI'
import {
    getSessionIsVet,
    saveIsVetInSession,
} from 'src/components/sections/UserAccount/utilities'

export type UserAccountContext = {
    isVet: boolean | null
    setIsVet: (a: boolean) => void
  }

const UserAccountContext = createContext({} as UserAccountContext)
export const UserAccountProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
    const [isVet, setIsVet] = useState<boolean | null>(null)

    const { person } = useSession()

    const {
        getIsVet,
    } = useUserAccountAPI()

    useEffect(() => {
        const sessionIsVet = getSessionIsVet()

        saveIsVetInSession(null)

        if (person?.email && sessionIsVet) {
            setIsVet(sessionIsVet)
            saveIsVetInSession(sessionIsVet)
        } else {
          person?.email &&
            getIsVet(person?.email).then((data: any) => {
              if (data) {
                setIsVet(data)
                saveIsVetInSession(data)
              }
            })
        }
    }, [person?.email])

    const value = {
        isVet,
        setIsVet,
    }

    return <UserAccountContext.Provider value={value}>{children}</UserAccountContext.Provider>
}

export const useUserAccountContext = () => {
    return useContext(UserAccountContext)
}
  
