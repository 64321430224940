import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import { useCallback } from 'react'
import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'

import { useCart } from './useCart'
import type { CartItem } from './validate'
import { removeCartItem } from './handleCartItems'

export const useRemoveButton = (
  item: CartItem | null,
  setIsVTEXValidating: (isValidating: boolean) => void
) => {
  const { removeItem: defaultRemoveItem, id: cartId } = useCart()
  const {
    currency: { code },
  } = useSession()

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()

      if (!item) {
        return
      }

      setIsVTEXValidating(true)

      const itemCategory = item.itemOffered.breadcrumbList?.itemListElement
        .slice(0, -1)
        .map((x) => x.name)

      const itemListName = itemCategory.join(' ')

      sendAnalyticsEvent<UnknownEvent>({
        name: 'remove_from_cart',
        params: {
          currency: code as CurrencyCode,
          value: item.price * item.quantity,
          items: [
            {
              index: 0,
              item_list_id: null,
              item_category: itemCategory,
              item_list_name: itemListName,
              item_id: item.itemOffered.isVariantOf.productGroupID,
              item_name: item.itemOffered.isVariantOf.name,
              item_brand: item.itemOffered.brand.name,
              item_variant: item.itemOffered.sku,
              quantity: item.quantity,
              price: item.price,
              discount: +(item.listPrice - item.price).toFixed(2),
              full_price: item.listPrice,
              discount_percentage: +(1 - item.price / item.listPrice).toFixed(
                2
              ),
              currency: code as CurrencyCode,
              item_variant_name: item.itemOffered.name,
              product_reference_id: Number(item.itemOffered.gtin),
              coupon: null,
            },
          ],
        },
      })

      removeCartItem(cartId, item)
        .then(() => {
          defaultRemoveItem(item.id)
          setIsVTEXValidating(false)
        })
        .catch(() => {
          setIsVTEXValidating(false)
        })
    },
    [code, item, defaultRemoveItem, cartId, setIsVTEXValidating]
  )

  return {
    onClick,
    'data-testid': 'remove-from-cart-button',
    'data-sku': item?.itemOffered.sku,
  }
}
